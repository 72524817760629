<template>
  <v-container :class="[
    'px-4 pt-0 pb-6 bigScreenForm competition-structure',
    hasRegionsFeatures ? 'competition-structure--has-regions' : null
  ]"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <div class="d-flex py-9">
          <v-icon color="primary" size="24" class="mr-2" v-text="'lightbulb_outline'" />
          {{ $t(disclaimer) }}
        </div>
        <div class="d-flex flex-column flex-sm-row">
          <div
            v-if="hasRegionsFeatures"
            class="mr-4 pa-2 rounded-lg d-flex justify-space-between flex-column competition-structure__container-elements mb-2 mb-sm-0 w-100 w-sm-50"
          >
            <h3 class="mb-4" v-text="$t('common.regions')" />
            <div class="d-flex flex-column justify-start flex-grow-1 flex-shrink-1 overflow-y-auto">
              <competition-region-item
                v-for="(region, index) in regions" :key="region.id" :item="region" :index="getIndex(index)"
                :class="{selected: selectedRegionId === region.id}"
                @onSave="saveRegion($event)" @onDelete="deleteRegion($event)" @onSelectRegion="selectedRegionId = $event"
              />
              <competition-region-item
                v-if="addNewItemRegion"
                is-new-item :item="{ name: null }" :index="getIndexNewItemRegion()" :names-items="namesRegions"
                @onSave="saveRegion($event)" @closeDialog="addNewItemRegion = false"
              />
            </div>
            <v-btn class="w-auto justify-start" icon small color="primary" @click="addNewItemRegion = true">
              <v-icon dark class="mx-1" v-text="'add_box'" />
              <span v-text="$t('project.competition.structure.addRegion')" />
            </v-btn>
          </div>
          <div class="mr-4 pa-2 rounded-lg d-flex justify-space-between flex-column competition-structure__container-elements w-100 w-sm-50">
            <div>
              <h3 class="d-inline-block mb-4" v-text="$t('common.categories')" /><span v-if="nameRegionSelected" v-text="` - ${nameRegionSelected}`" />
            </div>
            <div class="d-flex flex-column justify-start flex-grow-1 flex-shrink-1 overflow-y-auto">
              <competition-category-item
                v-for="(category, index) in categories" :key="category.id" :item="category" :index="getIndex(index)"
                :is-selected="categoriesIdSelected.includes(category.id)" :selected-region-id="selectedRegionId"
                :visible-check="!!(hasRegionsFeatures && selectedRegionId)" :can-delete="canDelete"
                :class="{selected: categoriesIdSelected.includes(category.id)}"
                @onSave="saveCategory($event)" @onDelete="deleteCategory($event)" @onSelectCategory="categoryToRegionAndCohort($event)"
              />
              <competition-category-item
                v-if="addNewItemCategory" :can-delete="canDelete"
                is-new-item :item="{ name: null }" :index="getIndexNewItemCategory()" :names-items="namesCategories"
                @onSave="saveCategory($event)" @closeDialog="addNewItemCategory = false"
              />
            </div>
            <v-btn class="w-auto justify-start" icon small color="primary" @click="addNewItemCategory = true">
              <v-icon dark class="mx-1" v-text="'add_box'" />
              <span v-text="$t('project.competition.structure.addCategory')" />
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompetitionStructure',
  components: {
    CompetitionRegionItem: () => import('@/modules/competition/structure/CompetitionRegionItem'),
    CompetitionCategoryItem: () => import('@/modules/competition/structure/CompetitionCategoryItem'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data: () => ({
    addNewItemRegion: false, addNewItemCategory: false, selectedRegionId: null, categoryIds: [],
  }),
  computed: {
    ...mapGetters('competition', ['regions', 'categories', 'cohorts', 'clubs', 'clubEnrollments']),
    ...mapGetters('project', ['hasRegionsFeatures']),
    canDelete: ({ clubs, clubEnrollments }) => clubs.length === 0 && clubEnrollments.length === 0,
    disclaimer: ({ hasRegionsFeatures }) => hasRegionsFeatures
      ? 'project.competition.structure.disclaimerHasRegionFeatures' : 'project.competition.structure.disclaimerNoHasRegionFeatures',
    categoriesIdSelected() {
      return this.categories.filter(c => this.regions.find(r => r.id === this.selectedRegionId)?.categoryIds?.includes(c.id))
        .map(c => c.id)
    },
    nameRegionSelected: ({ regions, selectedRegionId }) => selectedRegionId ? regions?.find(r => r.id === selectedRegionId).name : null,
    namesRegions: ({ regions }) => regions.map(r => r.name),
    namesCategories: ({ categories }) => categories.map(c => c.name),
  },
  watch: {
    selectedRegionId() {
      this.categoryIds = this.categoriesIdSelected
    },
  },
  methods: {
    getIndex(index) {
      if (['xs'].includes(this.$vuetify.breakpoint.name) && this.hasRegionsFeatures) return 1
      return index < 6 ? index : 6
    },
    getIndexNewItemRegion() {
      if (['xs'].includes(this.$vuetify.breakpoint.name)) return 1
      return this.regions.length < 6 ? this.regions.length : 6
    },
    getIndexNewItemCategory() {
      if (['xs'].includes(this.$vuetify.breakpoint.name)) return 1
      return this.categories.length < 6 ? this.categories.length : 6
    },
    async saveRegion(dataToSave) {
      const { organizationId, projectId } = this
      const { id, name, isNewItem } = dataToSave
      this.addNewItemRegion = false
      const pathAction = isNewItem ? 'competition/createRegion' : 'competition/updateRegion'
      const data = { name, ...(isNewItem && { id }) }
      await this.runAsync(() => this.$store.dispatch(pathAction, { organizationId, projectId, regionId: id, data }))
    },

    async deleteRegion(regionId) {
      const { organizationId, projectId } = this
      await this.runAsync(() => this.$store.dispatch('competition/deleteRegion', { organizationId, projectId, regionId }))

      this.runAsync(() => Promise.all(this.cohorts.filter(c => c.regionId === regionId)
        .map(c => this.$store.dispatch('competition/deleteCohort', { organizationId, projectId, cohortId: c.id }))))

      this.selectedRegionId = null
    },

    async saveCategory(dataToSave) {
      const { organizationId, projectId } = this
      const { id, name, isNewItem } = dataToSave
      this.addNewItemCategory = false
      const pathAction = isNewItem ? 'competition/createCategory' : 'competition/updateCategory'
      const data = { name, ...(isNewItem && { id }) }
      await this.runAsync(() => this.$store.dispatch(pathAction, { organizationId, projectId, categoryId: id, data }))
      if (!this.regions.length && isNewItem) {
        await this.createCohort(id)
      }
    },

    async deleteCategory(categoryId) {
      const { organizationId, projectId } = this
      await this.runAsync(() => this.$store.dispatch('competition/deleteCategory', { organizationId, projectId, categoryId }))

      this.runAsync(() => Promise.all(this.cohorts.filter(c => c.categoryId === categoryId)
        .map(c => this.$store.dispatch('competition/deleteCohort', { organizationId, projectId, cohortId: c.id }))))
    },

    async categoryToRegionAndCohort(dataCategory) {
      const { organizationId, projectId } = this
      if (dataCategory.checked) {
        await this.createCohort(dataCategory.id)
        this.categoryIds.push(dataCategory.id)
      } else {
        const cohortId = `${this.selectedRegionId ? `${this.selectedRegionId}-` : ''}${dataCategory.id}`
        await this.runAsync(() => this.$store.dispatch('competition/deleteCohort', { organizationId, projectId, cohortId }))
        this.categoryIds = this.categoryIds.filter(c => c !== dataCategory.id)
      }
      const data = { categoryIds: this.categoryIds }
      await this.runAsync(() => this.$store.dispatch('competition/updateRegion', { organizationId, projectId, regionId: this.selectedRegionId, data }))
    },

    async createCohort(categoryId) {
      const { organizationId, projectId } = this
      const cohortId = `${this.selectedRegionId ? `${this.selectedRegionId}-` : ''}${categoryId}`
      const data = {
        id: cohortId,
        regionId: this.selectedRegionId ?? null,
        categoryId,
        active: false,
        dataByStageId: {},
      }
      await this.runAsync(() => this.$store.dispatch('competition/createCohort', { organizationId, projectId, cohortId, data }))
    },
  },
}
</script>
<style lang="scss" scoped>
.competition-structure__container-elements {
  background: $inactive;
  height: 66vh;
  border: 2px solid rgb(218, 218, 218);
  box-shadow: rgb(245 245 245) 2px 2px 2px 1px;
}

.competition-structure {
  &--has-regions {
    .competition-structure__container-elements {
      height: calc(50vh - 128px);
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        height: 66vh;
      }
    }
  }
}

@import '@/styles/scrollbar-mac';

</style>
